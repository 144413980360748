import Layout from '../../components/layout'
import Container from '@material-ui/core/Container/Container'

const Ylabel = props => {
  return (
    <Layout
      backgroundColor="#FAFAFA"
      pageTitle={`YLabel - Free and Open Source Document Categorization Tool `}
      pageDescription={
        'Ylabel is a free and open source document categorization tool. It features search and active learning while running in the users browser'
      }
    >
      <iframe
        style={{
          width: '100%',
          height: 'calc(100vh - 94px) ',
          padding: '1rem',
          boxShadow: '0px 3px 12px rgba(0,0,0,.16)',
        }}
        src={'https://lighttag.github.io/ylabel2/'}
        frameBorder="0"
        allowFullScreen
      />
    </Layout>
  )
}

export default Ylabel
